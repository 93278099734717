<template>
  <div class="body fixed mobile ">
    <section class="header store_register">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="$router.push('/profile')">
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
        </a>
      </div>
      <span class="fL nowrap">{{ $t('send-gold') }}</span>
      <div class="flexH width right">
        <!-- <a class="textButton fM third">草稿暫存</a> -->
      </div>
    </section>
    <div class="main">
      <div class="bg-white flex p-1 mt-4 items-center justify-between text-lg rounded-lg">
        <div class="flex items-center">
          <CoinIcon />
          <span>{{ $t('hold-coins') }}</span>
        </div>
        <span>{{ $t('coin-unit', { coin: 500 }) }}</span>
      </div>
      <div class="mt-2 mb-2">{{ $t('send-desc') }}</div>
      <div class="mt-1">
        <div class="dropdown p-1 rounded bg-white mt-2">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'gift']" />
          </div>
          <span class="fM nowrap gray">{{ $t('send-target') }}<span class="text-red-500">*</span></span>
          <select name="type" class="fM rounded" dir="rtl">
          </select>
          <div class="arrow">
            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
          </div>
        </div>
        <div class="dropdown p-1 rounded bg-white mt-2">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'gift']" />
          </div>
          <span class="fM nowrap gray">{{ $t('send-group-target') }}<span class="text-red-500">*</span></span>
          <select name="type" class="fM rounded" dir="rtl">
          </select>
          <div class="arrow">
            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
          </div>
        </div>
        <div class="flex items-center p-1 rounded bg-white mt-2">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'gift']" />
          </div>
          <span class="fM nowrap gray">{{ $t('send-amount') }}<span class="text-red-500">*</span></span>
          <input type="text" class="flex-1 ml-1" v-model="amount">
        </div>
        <div class="p-1 rounded flex items-center bg-white mt-2 justify-between">
          <span>{{ $t('banlance-after-send') }}</span>
          <span>{{ $t('coin-unit', { coin: 500 }) }}</span>
        </div>
        <div class="flex mt-2">
          <button class="rounded p-1 flex-1" disabled>{{ $t('confirm-send') }}</button>
        </div>
      </div>
      <section v-if="showConfirm" class="popup">
        <div class="popBody">
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div>
              {{ $t('confirm-send-title') }}
            </div>
            <span class="text-center mt-2">{{ $t('confirm-send-desc', { coin: 500, user: '0919892632' }) }}</span>
          </section>
          <section class="popFooter">
            <button class="button rounded white cancel" @click="showConfirm = false">{{ $t('cancel') }}</button>
            <a class="button submit rounded">
              <span class="fM">{{ $t('confirm') }}</span>
            </a>
          </section>
        </div>
      </section>
      <section v-if="showSendSuccess" class="popup">
        <div class="popBody">
          <section class="popHeader">
            <!-- <span class="fL">Title</span> -->
          </section>
          <section class="popMain">
            <div>
              {{ $t('send-success') }}
            </div>
            <span class="text-center mt-2">{{ $t('send-success-desc', { coin: 500, user: '0919892632' }) }}</span>
          </section>
          <section class="popFooter">
            <button class="button submit rounded" @click="showSendSuccess = false">
              <span class="fM">{{ $t('confirm') }}</span>
            </button>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import CoinIcon from '../components/Icons/CoinIcon.vue';

export default {
  name: "Send",
  components: {
    CoinIcon,
  },
  data() {
    return {
      amount: 0,
      showConfirm: false,
      showSendSuccess: false,
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log(to, from);
  //   next((vm) => {
  //     let that = vm;
  //     if (that.user && that.user.userId) {
  //       //
  //     } else {
  //       next("/error");
  //     }
  //   });
  // },
}
</script>
<style scoped lang="scss">
button {
  border: 0;
  color: white;
  background-color: var(--c002);

  &:disabled {
    background: #bdbdbd;
  }

  &.cancel {
    color: black;
    border: 1px solid black;
  }
}

.popup {
  display: flex !important;
}
.dropdown {
  box-sizing: border-box;
}
</style>